import { Integrations } from '@sentry/tracing';

export const KEYS = {
  GOOGLE_KEY: 'AIzaSyBwkqAgYIoam33sENJ6c6zi4bqT5Y9JVTk',
  DOMAIN_URL: 'https://order.dev.cabdo.de',
  API_URL: 'https://api.dev.cabdo.de',
  DEFAULT_CITY: 'Dortmund',
  analyticsDataFlow: 'G-0EHT84PY03',
  facebookAppId: '661148130758720',
  googleClientId: '446447446170-kp9mh9kjvtuiqvrmubgdf081safik6hk.apps.googleusercontent.com',
  IS_DEV: true,
payPalOptions: {
    'client-id': 'AY22U-wJaWusj5_6NN2YQ7lKlqHnrjFoySbA0axAkUeBuOAnt1f9UW75Q8J1tvh8LH7UT9tt4vQyVe8y',
    currency: 'EUR',
    intent: 'capture',
    locale: 'de_DE'
  },
  sentryOptions: {
    dsn: 'https://5e72796d28a64f37937b11ac0f5a0b10@o563530.ingest.sentry.io/6108398',
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      'Error: Detected popup close',
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  }
};
